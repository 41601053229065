<template>
  <form v-on:submit.prevent.stop="doLogin">
    <div class="form-group">
      <input type="text" class="form-control" id="email" v-model="attributes.email"
             placeholder="Enter your email" :disabled="loading">
    </div>

    <div class="form-group">
      <input type="password" class="form-control" id="password" v-model="attributes.password"
             placeholder="Enter your password" :disabled="loading">
    </div>

    <button type="submit" class="btn btn-primary btn-block" :disabled="loading">
      Login
    </button>
  </form>

  <br />

  <!-- Microsoft Login -->
  <b-link 
    class="btn btn-success btn-block" 
    :href="`${config.buildUrl('api', '/api/connect/microsoft')}?scope=openid profile email Mail.Send`">
    <b-spinner small v-if="loading" :disabled="loading"/> Login with Microsoft Account
  </b-link>

  <div class="mt-3">
    <p class="error">{{ errorMessage }}</p>
  </div>

  <div class="mt-2 text-center">
    <p>Don't have an account?
      <b-button @click="toggleForm" variant="link">
        Sign Up
      </b-button>
    </p>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  data() {
    return {
      loading: false,
      attributes: {
        email: '',
        password: '',
      },
      errorMessage: ''
    };
  },
  setup() {
    const { profile, loginPw, loginJwt } = inject('user');
    const config = inject('config');
    return { profile, loginPw, loginJwt, config };
  },
  methods: {
    async doLogin() {
      this.loading = true;
      try {
        await this.loginPw(this.attributes.email, this.attributes.password);
        if (!this.profile.role) {
          this.profile.role = {};
        }

        const userProfileWithRole = {
          id: this.profile.id,
          username: this.profile.username,
          email: this.profile.email,
          role: this.profile.role,
        };

        // Store authentication token and user profile
        localStorage.setItem('authToken', this.loginJwtToken);
        localStorage.setItem('userProfile', JSON.stringify(userProfileWithRole));

        this.$bvToast.toast(`Welcome: ${this.profile.email}`, {
          title: 'Login successful',
          variant: 'success',
          solid: true,
        });

        this.$router.push({ name: 'home' });
      } catch (error) {
        this.handleAuthError(error);
      } finally {
        this.loading = false;
      }
    },

    handleAuthError(error) {
      this.errorMessage = error.response?.data?.error?.message || "Something went wrong";
      this.$bvToast.toast(this.errorMessage, {
        title: "Error",
        variant: "danger",
        solid: true,
      });
    },

    toggleForm() {
      this.$router.push({ name: "signup" });
    },
  },

  async mounted() {
    const url = new URL(window.location.toString().replace('/#', ''));

    if (url.searchParams.has('access_token')) {
      this.loading = true;

      try {
        const accessToken = url.searchParams.get('access_token');
        const res = await this.$xhr.get(`/api/auth/microsoft/callback?access_token=${accessToken}`);
        
        // Store the access token directly from the URL
        localStorage.setItem('authToken', accessToken);

        // Ensure the JWT is stored correctly
        await this.loginJwt(res.data.jwt);
        
        const userProfileWithRole = {
          id: this.profile.id,
          username: this.profile.username,
          email: this.profile.email,
          role: this.profile.role,
        };

        // Store user profile in localStorage
        localStorage.setItem('userProfile', JSON.stringify(userProfileWithRole));

        this.$bvToast.toast(`Welcome: ${this.profile.email}`, {
          title: 'Login successful',
          variant: 'success',
          solid: true,
        });

        this.$router.push({ name: 'home' });
      } catch (e) {
        console.log("MS Login error", e);
        this.handleAuthError(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  margin-top: 10px;
}
</style>
