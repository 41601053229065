<template>
  <div>
    <div class="d-flex align-items-center mb-3" style="margin-top: 2%;">
      <b-form-select v-model="filters.tourName" :options="tourNameOptions" class="mr-3" placeholder="Tour Name" />
      <b-form-select v-model="filters.tourCode" :options="tourCodeOptions" class="mr-3" placeholder="Tour Code" />
      <b-form-select v-model="filters.cruiseLine" :options="sortedUniqueCruiseLines" class="mr-3"
        placeholder="Cruise" />

      <b-form-select v-model="filters.year" :options="yearOptions" class="mr-3" placeholder="Year" />
      <b-form-select v-model="filters.port" :options="portOptions" class="mr-3" placeholder="Port" />
      <h5 class="mr-3">Tours({{ filteredTours.length }})</h5>
      <b-form-input v-model="searchQuery" class="mr-4" placeholder="Search..." />
      <b-button variant="secondary" v-b-tooltip.hover title="Reset Filters" @click="resetFilters"
        class="mr-3">Reset</b-button>
      <b-button class="mr-3" variant="primary" v-b-tooltip.hover title="Add New Tour" @click="showForm = !showForm">
        {{ showForm ? 'Cancel' : 'Add Tour' }}
      </b-button>
    </div>
    <div v-if="showForm">
      <TourForm @close="closeForm" @tour-added="fetchTours" :tour="selectedTour" />
    </div>
    <div class="table-responsive">
      <table class="table table-striped custom-table">
        <thead class="thead-dark">
          <tr>
            <th><input type="checkbox" @change="selectAll" /></th>
            <th @click="setSort('id')"> Id<span v-if="sortField === 'id'">{{ sortOrder === 'asc' ? '▲' : '▼' }}</span>
            </th>
            <th @click="setSort('tourName')">Tour Name<span v-if="sortField === 'tourName'">{{ sortOrder === 'asc' ? '▲'
              : '▼' }}</span></th>
            <th @click="setSort('tourCode')">Tour Code<span v-if="sortField === 'tourCode'">{{ sortOrder === 'asc' ? '▲'
              : '▼' }}</span></th>
            <th @click="setSort('cruiseLine')">Cruise Line<span v-if="sortField === 'cruiseLine'">{{ sortOrder === 'asc'
              ? '▲' : '▼' }}</span></th>
            <th @click="setSort('port')">Port<span v-if="sortField === 'port'">{{ sortOrder === 'asc' ? '▲' : '▼'
            }}</span></th>
            <th @click="setSort('year')">Year<span v-if="sortField === 'year'">{{ sortOrder === 'asc' ? '▲' : '▼'
            }}</span></th>
            <th>City</th>
            <th>Duration</th>
            <th>Pax Min</th>
            <th>Pax Max</th>
            <th>Tiers and Selling Prices</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tour in filteredTours" :key="tour.id" @contextmenu.prevent="showContextMenu($event, tour)"
            @click="selectedTours.includes(tour.id) ? selectedTours.splice(selectedTours.indexOf(tour.id), 1) : selectedTours.push(tour.id)"
            @dblclick="viewTour(tour.id)" class="tour-row">
            <td><input type="checkbox" v-model="selectedTours" :value="tour.id" /></td>
            <td>{{ tour.id }}</td>
            <td>{{ tour.attributes.tourName }}</td>
            <td>{{ tour.attributes.tourCode }}</td>
            <td>{{ tour.attributes.cruiseLine }}</td>
            <td>{{ tour.attributes.port?.data?.attributes?.city }}</td>
            <td>{{ tour.attributes.year }}</td>
            <td>
              <ul>
                <li v-for="city in tour.attributes.cities.data" :key="city.id">
                  {{ city.attributes.city }}
                </li>
              </ul>
            </td>
            <td>{{ tour.attributes.duration }}</td>
            <td>{{ tour.attributes.paxMin }}</td>
            <td>{{ tour.attributes.paxMax }}</td>
            <td>
              <table class="nested-table">
                <thead>
                  <tr>
                    <th>Tier</th>
                    <th>Adult</th>
                    <th>Child</th>
                    <th>E.Seats</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tier in tour.attributes.tiers" :key="tier.value">
                    <td>{{ tier.value }}</td>
                    <td>{{ formatPrice(tier.userInputSellingPriceAdult || 'N/A') }}</td>
                    <td>{{ formatPrice(tier.userInputSellingPriceChild || 'N/A') }}</td>
                    <td>{{ formatPrice(tier.userInputSellingPriceEmptySeat || 'N/A') }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
    <div v-if="showMenu" :style="{ top: `${menuY}px`, left: `${menuX}px` }" class="context-menu" ref="contextMenu">
      <ul>
        <li @click="viewTour(selectedTour.id)">View</li>
        <li @click="editSelectedTours">Edit</li>
        <li @click="cloneSelectedTours">Clone</li>
        <li @click="removeSelectedTours">Delete</li>
        <li @click="exportToExcel">Export</li>
      </ul>
    </div>

    <!-- Modal for cloning a tour -->
    <b-modal v-model="showCloneModal" title="Clone Tour" @ok="processClone">
      <b-form @submit.stop.prevent="processClone">
        <b-form-group label="Cruise Line:">
          <b-form-select v-model="newCruiseLine" :options="cruiseLineOptions" required></b-form-select>
        </b-form-group>
        <b-form-group label="Enter the year for the cloned tour:">
          <b-form-input v-model="newYear" type="number" required></b-form-input>
        </b-form-group>
        <b-form-group label="Tour Name:">
          <b-form-input v-model="newTourName" :value="selectedTour.attributes.tourName + ' (Clone)'"
            required></b-form-input>
        </b-form-group>
        <b-form-group label="Tour Code:">
          <b-form-input v-model="newTourCode" :value="selectedTour.attributes.tourCode" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>


    <!-- Modal for confirmation about missing items -->
    <b-modal v-model="showConfirmModal" title="Confirm Creation">
      <div v-if="matchedItems.length > 0" class="success-box">
        <h4>Matched Items</h4>
        <ul>
          <li v-for="item in matchedItems" :key="item.id">{{ item.attributes.item }}</li>
        </ul>
      </div>
      <div v-if="missingItems.length > 0" class="danger-box">
        <h4>Missing Items</h4>
        <ul>
          <li v-for="item in missingItems" :key="item.id">{{ item.name }}</li>
        </ul>
      </div>
      <div v-if="missingItems.length === 0 && matchedItems.length > 0">
        <p>All items matched for the selected year. Do you want to create the tour?</p>
      </div>
      <div v-if="missingItems.length > 0 && matchedItems.length > 0">
        <p>Do you still want to create the tour with missing items?</p>
      </div>
      <div v-if="missingItems.length === 0 && matchedItems.length === 0">
        <p>No items are in this tour. Do you want to create the tour without items?</p>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showConfirmModal = false">Cancel</b-button>
        <b-button variant="primary" @click="createClonedTour">Create Tour</b-button>
        <router-link :to="{ path: '/calculation/itemlist' }">
          <b-button variant="dark">Create New Item</b-button>
        </router-link>

      </template>
    </b-modal>

    <!-- Modal for deletion confirmation -->
    <b-modal v-model="showConfirmDeleteModal" title="Confirm Deletion">
      <p>Are you sure you want to delete the selected tours?</p>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showConfirmDeleteModal = false">Cancel</b-button>
        <b-button variant="danger" @click="confirmDeleteTours">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TourForm from './TourForm.vue';
import ServiceForm from './ServiceForm.vue';
import { formatPrice } from '../Helper/PriceDisplay.js';
import { generateExcel } from '../Helper/GenerateTourexcel.js';

export default {
  components: {
    TourForm,
    ServiceForm
  },
  data() {
    return {
      selectedRows: [],
      tours: [],
      loading: true,
      showForm: false,
      selectedTour: null,
      showCloneModal: false,
      showConfirmModal: false,
      showConfirmDeleteModal: false,
      newCruiseLine: '',
      newYear: '',
      newTourName: '',
      newTourCode: '',
      matchedItems: [],
      missingItems: [],
      clonedTour: null,
      selectedTours: [],
      showMenu: false,
      menuX: 0,
      menuY: 0,
      filters: {
        tourName: '',
        tourCode: '',
        cruiseLine: '',
        year: '',
        port: ''
      },
      searchQuery: '',
      sortField: 'id',
      sortOrder: 'asc' // 'asc' or 'desc'
    };
  },
  computed: {
    tourNameOptions() {
      return [{ value: '', text: 'Tour Name' }, ...this.sortedUniqueTourNames.map(name => ({ value: name, text: name }))];
    },
    tourCodeOptions() {
      return [{ value: '', text: 'Tour Code' }, ...this.sortedUniqueTourCodes.map(code => ({ value: code, text: code }))];
    },
    yearOptions() {
      return [{ value: '', text: 'Year' }, ...this.sortedUniqueYears.map(year => ({ value: year, text: year }))];
    },
    cruiseLineOptions() {
      return [
        'Norwegian Cruise Line', 'Princess Cruises', 'Azamara Cruises', 'Celebrity Cruises',
        'Compagnie Francaise de Croisieres', 'Crystal Cruises', 'Cunard', 'Disney Cruise Line',
        'Fred Olsen Cruise Lines', 'Holland America Line', 'Lindblad Expeditions', 'MSC Cruises',
        'Mystic Cruises', 'P&O Cruises', 'Regent Seven Seas Cruises', 'Virgin Voyages',
        'Windstar Cruises', 'Yachts of Seabourn', 'Oceania Cruises', 'Royal Caribbean International',
        'Viking Ocean Cruises', 'Compagnie du Ponant','Atlas Ocean Voyages'

      ];
    },
    portOptions() {
      return [{ value: '', text: 'Port' }, ...this.sortedUniquePorts.map(port => ({ value: port, text: port }))];
    },
    sortedUniqueTourNames() {
      return [...new Set(this.tours.map(tour => tour.attributes.tourName))].sort();
    },
    sortedUniqueCruiseLines() {
      return [{ value: '', text: 'Cruise Line' }, ...new Set(this.tours.map(tour => tour.attributes.cruiseLine))].sort();
    },
    sortedUniqueTourCodes() {
      return [...new Set(this.tours.map(tour => tour.attributes.tourCode))].sort();
    },
    sortedUniqueYears() {
      return [...new Set(this.tours.map(tour => tour.attributes.year))].sort((a, b) => a - b);
    },
    sortedUniquePorts() {
      return [...new Set(this.tours.map(tour => tour.attributes.port?.data?.attributes?.city))].sort();
    },
    filteredTours() {
      const sortedTours = [...this.tours].sort((a, b) => {
        let fieldA = a.attributes[this.sortField] || a[this.sortField] || '';
        let fieldB = b.attributes[this.sortField] || b[this.sortField] || '';

        // Handle numeric sorting for the `id` field specifically
        if (this.sortField === 'id') {
          fieldA = Number(fieldA);
          fieldB = Number(fieldB);
        } else {
          fieldA = String(fieldA);
          fieldB = String(fieldB);
        }

        if (this.sortOrder === 'asc') {
          return fieldA > fieldB ? 1 : -1;
        } else {
          return fieldA < fieldB ? 1 : -1;
        }
      });

      return sortedTours.filter(tour => {
        const port = tour.attributes.port?.data?.attributes?.city || '';
        const matchesSearchQuery = [tour.id, tour.attributes.tourName, tour.attributes.tourCode, tour.attributes.cruiseLine, port, tour.attributes.year]
          .some(field => String(field).toLowerCase().includes(this.searchQuery.toLowerCase()));

        return (
          matchesSearchQuery &&
          (!this.filters.tourName || tour.attributes.tourName === this.filters.tourName) &&
          (!this.filters.tourCode || tour.attributes.tourCode === this.filters.tourCode) &&
          (!this.filters.cruiseLine || tour.attributes.cruiseLine === this.filters.cruiseLine) &&
          (!this.filters.year || tour.attributes.year == this.filters.year) &&
          (!this.filters.port || port === this.filters.port)
        );
      });
    }

  },
  async created() {
    await this.fetchTours();
    document.addEventListener('click', this.handleClickOutside);
    this.loadFilters();

  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    formatPrice,
    setSort(field) {
      if (this.sortField === field) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortOrder = 'asc';
      }
    },
    async fetchTours(start = 0, limit = 150, reset = true) {
  if (start === 0) this.loading = true; // Show loading only for the initial batch
  if (reset) this.tours = []; // Clear the list before fetching to prevent duplicates

  const response = await this.$xhr.get(`/api/tours?populate=*&pagination[start]=${start}&pagination[limit]=${limit}`);

  const newTours = response.data.data.map(tour => ({
    ...tour,
    attributes: {
      ...tour.attributes,
      tiers: tour.attributes.tiers || [],
      item: tour.attributes.item || [] // Ensure items is an array
    }
  }));

  this.tours.push(...newTours);

  if (newTours.length === limit) {
    setTimeout(() => this.fetchTours(start + limit, limit, false),200); // Load next batch after 300ms
  } else {
    this.loading = false; // Stop loading indicator when all tours are loaded
  }
},


    viewTour(tourId) {
      this.$router.push({ name: 'TourDetails', params: { id: tourId } });
    },
    showContextMenu(event, tour) {
      event.preventDefault();
      this.selectedTour = tour;
      this.showMenu = true;
      this.menuX = event.clientX;
      this.menuY = event.clientY;
    },
    handleClickOutside(event) {
      const contextMenu = this.$refs.contextMenu;
      if (contextMenu && !contextMenu.contains(event.target)) {
        this.showMenu = false;
      }
    },
    editSelectedTours() {
      this.showForm = true;
      this.showMenu = false;
    },
    cloneSelectedTours() {
      this.newTourName = `${this.selectedTour.attributes.tourName} (Clone)`;
      this.newTourCode = this.selectedTour.attributes.tourCode;
      this.newYear = this.selectedTour.attributes.year;
      this.newCruiseLine = this.selectedTour.attributes.cruiseLine; // Preselect current cruise line
      this.showCloneModal = true;
      this.showMenu = false;
    },

    closeForm() {
      this.showForm = false;
      this.selectedTour = null;
    },
    removeSelectedTours() {
      this.showConfirmDeleteModal = true; // Show confirmation modal
      this.showMenu = false;
    },
    async confirmDeleteTours() {
  this.showConfirmDeleteModal = false; // Close the confirmation modal

  try {
    await Promise.all(this.selectedTours.map(tourId => this.$xhr.delete(`/api/tours/${tourId}`)));

    await this.fetchTours(); // Refresh the tours list
    this.$bvToast.toast('Tours deleted successfully!', {
      title: 'Success',
      variant: 'success',
      solid: true
    });
  } catch (error) {
    console.error('Error deleting tours:', error);
    this.$bvToast.toast('Failed to delete some tours. Please try again.', {
      title: 'Error',
      variant: 'danger',
      solid: true
    });
  }
},

    async processClone() {
      // Check if a tour with the same name, code, and year already exists
      const existingTourResponse = await this.$xhr.get(`/api/tours?filters[tourName][$eq]=${this.newTourName}&filters[cruiseLine][$eq]=${this.newCruiseLine}&filters[tourCode][$eq]=${this.selectedTour.attributes.tourCode}&filters[year][$eq]=${this.newYear}`);

      if (existingTourResponse.data.data.length > 0) {
        // Show a toast message if a tour already exists
        this.$bvToast.toast('This tour with the same name and code already exists for that year', {
          title: 'Clone Aborted',
          variant: 'danger',
          solid: true
        });
        this.showCloneModal = false;
        return; // Exit the function if a duplicate is found
      }

      // Fetch items for the new year
      const itemsResponse = await this.$xhr.get(`/api/services?filters[year][$eq]=${this.newYear}`);
      const newYearItems = itemsResponse.data.data;

      // Ensure item is an array and get existing items from the tour
      const existingItems = Array.isArray(this.selectedTour.attributes.item?.data)
        ? this.selectedTour.attributes.item.data.map(item => ({ id: item.id, name: item.attributes.item }))
        : [];

      // Separate matched and missing items
      this.matchedItems = [];
      this.missingItems = [];

      existingItems.forEach(existingItem => {
        const matchedItem = newYearItems.find(newItem => newItem.attributes.item.trim() === existingItem.name.trim());
        if (matchedItem) {
          this.matchedItems.push(matchedItem);
        } else {
          this.missingItems.push(existingItem);
        }
      });

      // Prepare cloned data with a new year and name
      this.clonedTour = {
        ...this.selectedTour,
        attributes: {
          ...this.selectedTour.attributes,
          year: this.newYear,
          tourName: this.newTourName,
          tourCode: this.newTourCode,
          cruiseLine: this.newCruiseLine,
          item: this.matchedItems.map(matchedItem => ({ id: matchedItem.id })),
          cities: Array.isArray(this.selectedTour.attributes.cities?.data) ? this.selectedTour.attributes.cities.data.map(city => ({ id: city.id })) : [],
          port: this.selectedTour.attributes.port?.data ? { id: this.selectedTour.attributes.port.data.id } : null,
          tiers: Array.isArray(this.selectedTour.attributes.tiers) ? this.selectedTour.attributes.tiers.map(tier => ({
            value: tier.value,
            // userInputSellingPriceAdult: tier.userInputSellingPriceAdult,
            // userInputSellingPriceChild: tier.userInputSellingPriceChild,
            // userInputSellingPriceEmptySeat: tier.userInputSellingPriceEmptySeat
          })) : [],
          remarks: this.missingItems.length > 0
            ? `Missing items:\n${this.missingItems.map((item, index) => `${index + 1}) ${item.name}`).join('\n')}`
            : ''
        }
      };

      // Show the confirmation modal with appropriate message
      if (this.matchedItems.length === 0 && this.missingItems.length === 0) {
        this.confirmationMessage = `No items found for the selected year. Do you want to create the tour with no items?`;
      } else if (this.matchedItems.length === 0) {
        this.confirmationMessage = `No items matched for the selected year. Do you still want to create the tour?`;
      } else if (this.missingItems.length === 0) {
        this.confirmationMessage = `All items matched for the selected year. Do you want to create the tour?`;
      } else {
        this.confirmationMessage = `Only ${this.matchedItems.length} items matched for the selected year:<br>`;
        this.confirmationMessage += `<ul>${this.matchedItems.map(item => `<li>${item.attributes.item}</li>`).join('')}</ul>`;
        this.confirmationMessage += `Missing items:<br>`;
        this.confirmationMessage += `<ul>${this.missingItems.map(item => `<li>${item.name}</li>`).join('')}</ul>`;
        this.confirmationMessage += `Do you still want to create the tour with missing items?`;
      }

      this.showCloneModal = false;
      this.showConfirmModal = true;
    },

    async createClonedTour() {
      const response = await this.$xhr.post('/api/tours', { data: this.clonedTour.attributes });
      console.log('Cloned tour response:', response.data);
      this.$bvToast.toast('Tour cloned successfully!', {
        title: 'Success',
        variant: 'success',
        solid: true
      });
      await this.fetchTours();
      this.showConfirmModal = false; // Close the confirmation modal
    },
    selectAll(event) {
      this.selectedTours = event.target.checked ? this.filteredTours.map(tour => tour.id) : [];
    },
    resetFilters() {
      this.filters = { tourName: '', tourCode: '', cruiseLine: '', year: '', port: '' };
      localStorage.removeItem('lastTourAppliedFilters');
    },
    saveFilters() {
      localStorage.setItem('lastTourAppliedFilters', JSON.stringify(this.filters));
    },
    loadFilters() {
      const savedFilters = localStorage.getItem('lastTourAppliedFilters');
      if (savedFilters) {
        this.filters = JSON.parse(savedFilters);
      }
    },
    exportToExcel() {
      const selectedRows = this.tours.filter(tour => this.selectedTours.includes(tour.id));
      if (selectedRows.length === 0) {
        this.$bvToast.toast('No rows selected for export!', {
          title: 'Export Failed',
          variant: 'warning',
          solid: true
        });
        return;
      }
      generateExcel(selectedRows);
    }

  },
  watch: {
    filters: {
      handler() {
        this.saveFilters();
      },
      deep: true
    }
  },
};
</script>
<style scoped>
.custom-table {
  margin-top: 4%;
  max-height: 65vh;
  overflow-y: auto;
  display: block;
}

.table-responsive {
  overflow-y: auto;
}

.table th,
.table td {
  width: 0.1%;
  text-align: center;

}

.table th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.align-items-center {
  align-items: center;
}

.context-menu {
  position: fixed;
  background-color: #e6e0e0;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 150px;
}

.context-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  padding: 10px;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #edc99b;
}

.nested-table {
  width: 100%;
  border-collapse: collapse;
}

.nested-table th,
.nested-table td {
  padding: 2px;
  border: 1px solid #ddd;
}

.success-box {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  padding: 10px;
}

.danger-box {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
}

.tour-row:hover {
  background-color: #edc99b;
}

.mb-3 {
  margin-bottom: 1rem;
  margin-top: 2%;
  margin-left: 1.4%;
}

.mr-3 {
  max-width: 14rem;
  margin-top: 20px;
}

.mr-4 {
  margin-right: 1rem;
  max-width: 20rem;
  margin-inline: 2rem;
  margin-top: 20px;
}

.addbtn {
  margin-top: 2rem;
  margin-left: 0rem;
}
</style>
